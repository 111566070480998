.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3.5rem;
    background-color: #23374D;
    border: solid;
    color: #E5E5E5;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;  
}

.footer p {
    font-size: 1rem;
}

.icons {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center; 
}

a {
    color: #E5E5E5; 
}

@media all and (max-width: 768px) {

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    .footer {
        border:solid;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: 23374D;
        color: #E5E5E5;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .footer p {
        font-size: .7rem;
    }
    
    
    .icons {
        display: flex;
        flex-direction: row;
        gap: .5rem;
        align-items: center;  
    }

    a {
        color: #E5E5E5;  
    }

}