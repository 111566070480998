
* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  height: 100vh;
  background-color:  #E5E5E5;
  background-image: url(maple_leaf.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clock {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-family: Montserrat, monospace;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 8px;
}

.clock timer-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.clock div p {
  font-size: 2.5rem; /*clock number font */

}
.clock div small {
  color: whitesmoke;
  text-shadow: none;
  font-size: .9rem;
}

.clock span {
  display: inline-block;
  background: #1089FF;
  color: white;
  min-width: 14rem;
  padding: .5%;
  margin-left: 40px;
  border-radius: 10px 0 0 10px;
  border-style: solid;
  border-color: #23374D;
  box-shadow: 0 0 0 .15rem #E5E5E5;
}

.timer h3 {
  font-size: 3rem;
  font-family: Montserrat;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-color: #23374D;
  box-shadow: 0 0 0 .15rem #E5E5E5;
  margin-bottom: 5rem;
  background-color: #1089FF;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-right: 50px;
}


@media all and (max-width: 768px) {

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .App {
    text-align: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color:#E5E5E5 ;
    background-size: fit;
  }

 .timer-container{
  flex: 1;
  display: flex;
  width: 90%;
  flex-direction: column;
 
 }

 .timer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%
 }
 
  .timer h3 { 
    font-size: 1.4rem;
    font-family: Montserrat;
    margin: 2rem 2rem -2rem 2rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 10px 0 0 10px;
    border-width: .3rem;
    flex-wrap: wrap;
    padding: .5rem;
    box-shadow: 0 0 0 .2rem #E5E5E5;
  }

  .clock span {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    background:  #1089FF;
    margin: .2rem;
    color: white;
    border-radius: 10px 0 0 10px;
    border-style: solid;
    border-color: #23374D;
    border-width: .3rem;
    min-width: 8rem;
    box-shadow: 0 0 0 .1rem #E5E5E5;
  }

  .clock {
    gap: .5rem;
    display: flex;  
    justify-content: center;
    flex-direction: column;   
    font-family: montserrat,monospace;
    font-weight: bold;  
    color: white;
    text-shadow: 1px 1px 10px;
  }

  .clock timer-box {
    vertical-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }

  .clock div p {
    font-size: 2rem; /*clock number font */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: .5rem;
  }

  .clock div small {
    color: whitesmoke;
    text-shadow: none;
    font-size: 1.5rem;
  }

}


